/* eslint-disable react/jsx-key */
import React from "react"
import { Grid, useMediaQuery } from "@material-ui/core"
import sectionStyles from "src/styles/components/mmm-section.module.scss"
import PropTypes from "prop-types"

const MMMAppSection = ({ children, titles, images, descriptions }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"))

  const desktopOrder = [
    <Grid item xs={12} md={4}>
      <h3>
        {titles[0] ||
          "Checken Sie Ihre mentale Gesundheit und erhalte Sie Ihren persönlichen Übungsplan"}
      </h3>
    </Grid>,
    <Grid item xs={12} md={4}>
      <h3>
        {titles[1] ||
          "Checken Sie Ihre mentale Gesundheit und erhalte Sie Ihren persönlichen Übungsplan"}
      </h3>
    </Grid>,
    <Grid item xs={12} md={4}>
      <h3>{titles[2] || "Große Auswahl an Übungen und Kursen"}</h3>
    </Grid>,
    <Grid item xs={12} md={4}>
      <img
        className="screenshot-col"
        alt="score"
        src={images[0] || "/assets/img/marketing/score.png"}
      />
    </Grid>,
    <Grid item xs={12} md={4}>
      <img
        className="screenshot-col"
        alt="traininsplan"
        src={images[2] || "/assets/img/marketing/trainingsplan.png"}
      />
    </Grid>,
    <Grid item xs={12} md={4}>
      <img
        className="screenshot-col"
        alt="feed"
        src={images[2] || "/assets/img/marketing/feed.png"}
      />
    </Grid>,
    <Grid item xs={12} md={4}>
      <p>
        {descriptions[0] ||
          `Unter Ihrem Profil finden Sie die Timeline Ihrer absolvierten Übungen,
        Statistiken über Ihr Training mit Mindance und die Ergebnisse des
        Fragebogens. Unser Tipp: Regelmäßiges Üben macht den Meister, damit ist
        Regelmäßigkeit erst einmal wichtiger als langes Üben.`}
      </p>
    </Grid>,
    <Grid item xs={12} md={4}>
      <p>
        {descriptions[1] ||
          `Sie starten mit einem Fragebogen, der Ihnen zeigt, in welchen Bereichen
        Sie Ihre psychische Gesundheit verbessern können. Auf Ihrem persönlichen
        Ergebnis basierend erhalten Sie einen Plan mit verschiedenen Übungen.
        Zum Schluss bestimmen Sie selbst, wie viel Zeit Sie pro Woche
        investieren möchten. Von einmal wöchentlich fünf Minuten, bis zu täglich
        morgens und abends jeweils 20 Minuten - Sie haben die Wahl.`}
      </p>
    </Grid>,
    <Grid item xs={12} md={4}>
      <p>
        {descriptions[2] ||
          `In der App finden Sie ein 8-wöchiges Grundlagentraining, das Sie dabei
        unterstützen kann, Ihre eigenen Gedanken und Gefühle besser zu
        verstehen. Außerdem können Sie nach Belieben aus Übungen zu
        verschiedenen Themenbereichen, wie Stress reduzieren, Beziehungen
        stärken, Besser schlafen oder Achtsamkeit kultivieren wählen und
        zwischen 5- und 15- Minuten die Übungslänge einstellen, die für Sie in
        dem Moment ideal ist.`}
      </p>
    </Grid>,
  ]

  const mobileOrder = [
    desktopOrder[0],
    desktopOrder[3],
    desktopOrder[6],
    desktopOrder[1],
    desktopOrder[4],
    desktopOrder[7],
    desktopOrder[2],
    desktopOrder[5],
    desktopOrder[8],
  ]

  return (
    <div className={sectionStyles.coloredResilience}>
      <Grid container component="section" className="bounded-w">
        <Grid item xs={12}>
          <h1>Die Mindance App </h1>
          {children}
        </Grid>
        <Grid container item xs={12} direction="row" spacing={2}>
          {isMobile ? mobileOrder : desktopOrder}
        </Grid>
      </Grid>
    </div>
  )
}

MMMAppSection.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.string),
  images: PropTypes.arrayOf(PropTypes.string),
  descriptions: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
}

MMMAppSection.defaultProps = {
  children: null,
  titles: [],
  images: [],
  descriptions: [],
}

export default MMMAppSection
