import React from "react"
import MMMHeader from "src/components/mmm-header"
import Layout from "src/components/layout"
import { Grid } from "@material-ui/core"
import EmailInput from "src/components/marketing/email-input"
import GetStartedSection from "src/components/marketing/get-started-section"
import TeamSection from "src/components/marketing/team-section"
import MMMAppSection from "src/components/marketing/mmm-app-section"
import SignatureSection from "src/components/marketing/signature-section"
import TitleStrip from "src/components/marketing/title-strip"
import getRegistrationCode from "src/helpers/get-registration-code.js"
import "src/styles/pages/mmm-page.scss"

export default function MMMResiliencePage() {
  const REGISTRATION_CODE = getRegistrationCode(
    process.env.GATSBY_DEFAULT_NO_CODE_COMPANY
  )

  return (
    <Layout className="landing-page-root">
      <TitleStrip
        logos={[
          "https://mindance-forestry.s3-eu-west-1.amazonaws.com/upload/logo@2x.png",
          "/assets/img/logos/familienservice.png",
        ]}
        links={["/", "https://www.familienservice.de/"]}
      />
      <MMMHeader
        className="marketing-jumbotron"
        section={{
          image: "/assets/img/courses/resilience/header.png",
          title: "Gruppencoaching „Resilienz“",
          description:
            "Herzlich Willkommen zum Gruppencoaching „Resilienz“ und zur Mindance App, die wir in diesem Gruppencoaching einbeziehen und die Dir ab sofort kostenfrei für 3 Monate zur Verfügung stehen wird. Aber keine Angst – es läuft kein Abonnement anschließend weiter, sondern Du selbst hast es in der Hand, die App auch weiter nutzen zu können. Wie Du das (nahezu) kostenfrei für weitere 12 Monate machen kannst, erfährst Du weiter unten auf dieser Seite.",
        }}
      >
        <EmailInput
          short
          placeholder="E-Mail"
          registrationCode={REGISTRATION_CODE}
        />
      </MMMHeader>
      <Grid container component="section" className="bounded-w">
        <Grid item xs={12} md={6}>
          <h1>Was erwartet Dich nun im Coaching und mit der App?</h1>
          <p>
            Basierend auf dem Konzept der Achtsamkeit, haben Mindance und der
            pme Familienservice die wissenschaftlich fundierten Gruppencoachings
            Beziehungen, Resilienz und Fokussiertes Arbeiten entwickelt.
            Resiliente Menschen bringen eine Widerstandskraft mit, sich nicht
            von Stress, Krisen und Schicksalsschlägen aus der Bahn werfen zu
            lassen. Sie machen das Beste aus der Situation, lernen daraus und
            wachsen sogar über sich selbst hinaus. Resilienz ist also die Kraft,
            weiter zu machen und sich den Herausforderungen zu stellen. Und das
            kann jede/r lernen. In den einzelnen Sitzungen des Gruppencoachings
            erfährst Du, an welchen Fähigkeiten Du ansetzen kannst, um Deine
            Widerstandsfähigkeit gegen Stress in herausfordernden und kritischen
            Situationen zu stärken. Wir laden Dich ein, Deine Fragen und
            Erfahrungen einzubringen und mit anderen Teilnehmenden zu teilen.
          </p>
          <br />
          <p>
            <b className="green-text text-l">
              Das Gruppencoaching wird insgesamt 6 Wochen dauern und aus 3
              Gruppentreffen, der Mindance App und einem Arbeitsbuch bestehen.
            </b>
          </p>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className="screenshot-container cover-section-edge"
        >
          <img
            className="screenshot"
            alt="feed"
            src="/assets/img/courses/resilience/overlapping_app_screens.jpeg"
          />
        </Grid>
      </Grid>
      <MMMAppSection
        titles={[
          "Trainiere in gemeinsam mit deiner Buddy Group",
          "Checken Sie Ihre mentale Gesundheit und erhalten Sie Ihren persönlichen Übungsplan",
          "Entdecken Sie die große Auswahl an Übungen und Kursen",
        ]}
        descriptions={[
          "In dieser Rubrik findest Du ausgewählte Übungen, die Du gemeinsam mit Deiner Buddy Group während der Gruppentreffen durchführst. Jedes Gruppentreffen beginnt jeweils mit einer Check-In-Übung und endet mit einer Check-Out-Übung. Viel Spaß!",
          "Starten Sie direkt mit unserem Fragebogen, der Ihre psychische Gesundheit detailliert erfasst. Die Ergebnisse zeigen auf, in welchen Bereichen Ihre mentale Gesundheit einer Förderung bedarf. Auf Ihrem persönlichen Ergebnis basierend erhalten Sie einen Plan mit verschiedenen Übungen. Zum Schluss bestimmen Sie selbst, wie viel Zeit Sie pro Woche investieren möchten. Ob einmal wöchentlich 5 Minuten oder täglich 20 Minuten - Sie haben die Wahl",
          "In der App finden Sie ein 8-wöchiges Grundlagentraining, das Sie dabei unterstützen kann, Ihre eigenen Gedanken und Gefühle besser zu verstehen. Außerdem können Sie nach Belieben aus Übungen zu verschiedenen Themenbereichen, wie “Stress reduzieren”, “Beziehungen stärken”, “Besser schlafen” oder “Achtsamkeit kultivieren” wählen und zwischen 5 und 15 Minuten die Übungslänge einstellen, die für Sie in dem Moment ideal ist.",
        ]}
        images={["/assets/img/courses/group-screenshot.png"]}
      >
        <p>
          Begleitet wird das Gruppencoaching durch die App „Mindance“ die Dir
          ein sehr wertvoller Begleiter und „Trainingspartner“ für Deine eigene
          mentale Stärke im Alltag sein kann. Denn, Gesundheit beginnt im Kopf!
          Mindance bietet Dir eine in Deutschland fachlich führende App zur
          mentalen Gesundheit, mit zahlreichen, sehr individuell einstellbaren
          audiogeführten Übungen zur Entspannung, mentalen Stärkung, Achtsamkeit
          und Meditation. Mit dem Mindance Score erhältst Du auf der App eine
          kostenfreie und wissenschaftlich erprobte Auswertung Deiner mentalen
          Gesundheit und kannst mit einem individuell zugeschnitten
          Trainingsplan sofort daran arbeiten, etwas für Dich und Deine
          Gesundheit und Stärke zu tun.
        </p>
      </MMMAppSection>
      <TeamSection>
        <h1>
          Individuelles Coaching und Austausch mit anderen Teilnehmer:innen über
          die Chatfunktion
        </h1>
        <br />
        <p>
          In den ersten 6 Wochen Deiner Mindance-Nutzung wirst Du mit dem Coach
          von pme als auch mit den anderen Teilnehmer:innen des Gruppencoachings
          über die App in einem Chat-Austausch stehen können. In den zweiten 6
          Wochen steht euch als Teilnehmer:innen auch weiterhin der Austausch
          untereinander offen, sodass ihr im Kontakt bleiben und euch
          gegenseitig inspirieren und stärken könnt.
        </p>
      </TeamSection>
      <div className="zpp-container">
        <Grid container component="section" className="bounded-w">
          <Grid
            item
            xs={12}
            md={6}
            className="screenshot-container cover-section-edge"
          >
            <img
              className="screenshotLeft"
              alt="feed"
              src="/assets/img/spmr/1.png"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <h2 className="green-text">
              <a href="https://mindance.de/spmr">
                Und so kannst Du die App auch über die 3 Monate hinaus weitere
                12 Monate (nahezu) kostenfrei nutzen!
              </a>
            </h2>
            <p>
              Als gesetzlich Krankenversicherte/r stehen Dir über deine
              Leistungen der Krankenkasse auch sogenannte Präventionsleistungen
              kostenfrei zur Verfügung. Mindance stellt solche Präventionskurse
              über die App zur Verfügung, die über 8 Wochen gehen (pro Woche 45
              Minuten). Buchst Du einen dieser Kurse, bezahlst Du zunächst 75€.
              Absolvierst Du diesen Kurs vollständig, erhältst Du ein
              Zertifikat, mit welchem Du bei Deiner Krankenkasse eine bis zu
              100%ige Erstattung der Kosten beantragen kannst. Damit hast Du
              aber nicht nur einen kostenfreien bzw. sehr kostengünstigen Kurs,
              sondern auch die App für weitere 12 Monate vollkommen kostenfrei
              zur Verfügung. Daher nutze diese Möglichkeit unbedingt und buche
              noch vor Ablauf der 3 freien Test-Monate Deinen ZPP-Kurs bei
              Mindance.
              <br />
              <a href="https://mindance.de/spmr">
                Diese(n) findest Du immer unter www.mindance.de und dem
                Navigationspunkt „Zertifizierte Kurse“.
              </a>
            </p>
          </Grid>
        </Grid>
      </div>
      <GetStartedSection
        steps={[
          "Laden Sie sich die Mindance App im AppStore/Google PlayStore und dem Stichwort “Mindance” herunter.",
          "Geben Sie hier Ihre E-Mail Adresse ein, um einen Aktivierungscode zu erhalten.",
          "Erstellen Sie sich unter Verwendung Ihres Aktivierungscodes Ihr persönliches, anonymes Profil.",
        ]}
        emailComponent={
          <EmailInput
            placeholder="E-Mail Adresse"
            registrationCode={REGISTRATION_CODE}
          />
        }
      />
      <SignatureSection />
    </Layout>
  )
}
