import React from "react"
import { Grid } from "@material-ui/core"
import PropTypes from "prop-types"

const SignatureSection = ({ text, signature }) => {
  return (
    <Grid container justify="center" component="section" className="short">
      <h2 className="green-text signature">
        {text}, <br />
        {signature}
      </h2>
    </Grid>
  )
}

SignatureSection.propTypes = {
  text: PropTypes.string,
  signature: PropTypes.string,
}

SignatureSection.defaultProps = {
  text: "Viel Spaß und liebe Grüße",
  signature: "Das Mindance Team",
}

export default SignatureSection
